var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"m-0"},[_c('div',{staticClass:"wrapper-investor-page"},[_c('h1',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t('space_investissor.title')))]),_c('section',[_c('h3',[_vm._v(_vm._s(_vm.$t('space_investissor.sub_title1')))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('space_investissor.description'))+" ")]),_c('ul',[_c('li',[_vm._v(_vm._s(_vm.$t('space_investissor.list1')))]),_c('li',[_vm._v(" "+_vm._s(_vm.$t('space_investissor.list2'))+" ")]),_c('li',[_vm._v(_vm._s(_vm.$t('space_investissor.list3')))]),_c('li',[_vm._v(_vm._s(_vm.$t('space_investissor.list4')))])])]),_c('section',[_c('h3',[_vm._v(_vm._s(_vm.$t('space_investissor.sub_title2')))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('space_investissor.description2'))+" ")]),_c('ul',[_c('li',[_vm._v(_vm._s(_vm.$t('space_investissor.list5')))]),_c('li',[_vm._v(_vm._s(_vm.$t('space_investissor.list6')))]),_c('li',[_vm._v(_vm._s(_vm.$t('space_investissor.list7')))]),_c('li',[_vm._v(_vm._s(_vm.$t('space_investissor.list8')))])])]),_c('section',[_c('h3',[_vm._v(_vm._s(_vm.$t('space_investissor.sub_title3')))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('space_investissor.description3'))+" ")])]),_c('section',[_c('p',[_vm._v(" "+_vm._s(_vm.$t('space_investissor.description4'))+" ")])])])]),_c('div',{staticClass:"contact"},[_c('img',{staticClass:"back-image",attrs:{"src":require('@/assets/images/tourismeBenin/creds.jpg'),"alt":""}}),_c('div',{staticClass:"filigrane-color"}),_c('div',{staticClass:"contact-wrapper"},[_c('div',{staticClass:"contact-form"},[_c('h1',[_vm._v("Contact")]),_c('validation-observer',{ref:"formRules"},[_c('div',{staticClass:"contact-names-email"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"w-100",attrs:{"label":_vm.$t('investorPage.names'),"label-for":"names"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('investorPage.names'))+" "),_c('RequiredStar')],1)]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('investorPage.namesPlaceholder'),"id":"names","type":"text"},model:{value:(_vm.objectMsg.nomPrenoms),callback:function ($$v) {_vm.$set(_vm.objectMsg, "nomPrenoms", $$v)},expression:"objectMsg.nomPrenoms"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"w-100",attrs:{"label":_vm.$t('investorPage.mail'),"label-for":"email"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('investorPage.mail'))+" "),_c('RequiredStar')],1)]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('investorPage.mailPlaceholder'),"id":"email"},model:{value:(_vm.objectMsg.mail),callback:function ($$v) {_vm.$set(_vm.objectMsg, "mail", $$v)},expression:"objectMsg.mail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('validation-provider',{attrs:{"rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('investorPage.number'),"label-for":"phoneNumber"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('investorPage.number'))+" "),_c('RequiredStar')],1)]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('investorPage.numberPlaceholder'),"id":"phoneNumber","type":"number"},model:{value:(_vm.objectMsg.contact),callback:function ($$v) {_vm.$set(_vm.objectMsg, "contact", $$v)},expression:"objectMsg.contact"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('investorPage.object'),"label-for":"sujet"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('investorPage.object'))+" "),_c('RequiredStar')],1)]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('investorPage.objectPlaceholder'),"id":"sujet"},model:{value:(_vm.objectMsg.sujet),callback:function ($$v) {_vm.$set(_vm.objectMsg, "sujet", $$v)},expression:"objectMsg.sujet"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('div',{staticClass:"mt-1"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('investorPage.message'),"label-for":"message"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('investorPage.message'))+" "),_c('RequiredStar')],1)]},proxy:true}],null,true)},[_c('b-form-textarea',{staticClass:"w-100",attrs:{"id":"message","placeholder":_vm.$t('investorPage.messagePlaceholder')},model:{value:(_vm.objectMsg.message),callback:function ($$v) {_vm.$set(_vm.objectMsg, "message", $$v)},expression:"objectMsg.message"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('div',{staticClass:"mt-2 d-flex justify-content-center"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"d-flex align-items-center",attrs:{"variant":"primary","disabled":_vm.isSending},on:{"click":function($event){return _vm.envoyer()}}},[(_vm.isSending)?_c('b-spinner',{staticClass:"mr-1",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('button.send'))+" ")],1)],1)],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }