/* eslint-disable global-require */
<template>
  <div>
    <b-card class="m-0">
      <div class="wrapper-investor-page">
        <h1 class="mb-2">{{$t('space_investissor.title')}}</h1>
        <!-- <h1>Opportunités d'Investissement Touristique au Bénin</h1> -->

        <section>
          <h3>{{$t('space_investissor.sub_title1')}}</h3>
          <p>
            {{$t('space_investissor.description')}}
          </p>
          <ul>
            <li>{{$t('space_investissor.list1')}}</li>
            <li>
              {{$t('space_investissor.list2')}}
            <li>{{$t('space_investissor.list3')}}</li>
            <li>{{$t('space_investissor.list4')}}</li>
          </ul>
        </section>

        <section>
          <h3>{{$t('space_investissor.sub_title2')}}</h3>
          <p>
            {{$t('space_investissor.description2')}}
          </p>
          <ul>
            <li>{{$t('space_investissor.list5')}}</li>
            <li>{{$t('space_investissor.list6')}}</li>
            <li>{{$t('space_investissor.list7')}}</li>
            <li>{{$t('space_investissor.list8')}}</li>
          </ul>
        </section>

        <section>
          <h3>{{$t('space_investissor.sub_title3')}}</h3>
          <p>
            {{$t('space_investissor.description3')}}
          </p>
        </section>

        <section>
          <p>
            {{$t('space_investissor.description4')}}
          </p>
        </section>
      </div>
    </b-card>
    <div class="contact">
      <img
        :src="require('@/assets/images/tourismeBenin/creds.jpg')"
        alt=""
        class="back-image"
      />
      <div class="filigrane-color" />
      <div class="contact-wrapper">
        <!-- <div class="left-infos">
          <h1>Vous avez une question ?</h1>
        <p>
          Merci de nous contacter en écrivant à cette adresse email:
          mtca.dsisa@gouv.bj. <br />
          Nous mettons tout en oeuvre pour répondre le plus rapidement possible.
        </p>
        <h3>
          Heures d'Ouverture : <br />
          Lundi - Vendredi 8h - 17h30
        </h3>
      </div> -->
        <div class="contact-form">
          <!-- <router-link to="/" class="logo mx-auto">
          <img
            :src="require('@/assets/images/logo/logo_mtca.png')"
            alt="logo"
          />
        </router-link> -->

          <h1>Contact</h1>
          <!-- <h1>{{ $t('investorPage.title') }}</h1>
          <p>
            {{ $t('investorPage.desc') }}
          </p> -->

          <validation-observer ref="formRules">
            <div class="contact-names-email">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-group
                  class="w-100"
                  :label="$t('investorPage.names')"
                  label-for="names"
                >
                  <template v-slot:label>
                    <span
                      >{{ $t('investorPage.names') }}
                      <RequiredStar />
                    </span>
                  </template>
                  <b-form-input
                    :placeholder="$t('investorPage.namesPlaceholder')"
                    id="names"
                    type="text"
                    v-model="objectMsg.nomPrenoms"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <validation-provider v-slot="{ errors }" rules="required|email">
                <b-form-group
                  class="w-100"
                  :label="$t('investorPage.mail')"
                  label-for="email"
                >
                  <template v-slot:label>
                    <span
                      >{{ $t('investorPage.mail') }}
                      <RequiredStar />
                    </span>
                  </template>
                  <b-form-input
                    :placeholder="$t('investorPage.mailPlaceholder')"
                    id="email"
                    v-model="objectMsg.mail"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </div>
            <validation-provider v-slot="{ errors }" rules="required|integer">
              <b-form-group
                :label="$t('investorPage.number')"
                label-for="phoneNumber"
              >
                <template v-slot:label>
                  <span
                    >{{ $t('investorPage.number') }}
                    <RequiredStar />
                  </span>
                </template>
                <b-form-input
                  :placeholder="$t('investorPage.numberPlaceholder')"
                  id="phoneNumber"
                  type="number"
                  v-model="objectMsg.contact"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <validation-provider v-slot="{ errors }" rules="required">
              <b-form-group
                :label="$t('investorPage.object')"
                label-for="sujet"
              >
                <template v-slot:label>
                  <span
                    >{{ $t('investorPage.object') }}
                    <RequiredStar />
                  </span>
                </template>
                <b-form-input
                  :placeholder="$t('investorPage.objectPlaceholder')"
                  id="sujet"
                  v-model="objectMsg.sujet"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <div class="mt-1">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-group
                  :label="$t('investorPage.message')"
                  label-for="message"
                >
                  <template v-slot:label>
                    <span
                      >{{ $t('investorPage.message') }}
                      <RequiredStar />
                    </span>
                  </template>
                  <b-form-textarea
                    id="message"
                    v-model="objectMsg.message"
                    class="w-100"
                    :placeholder="$t('investorPage.messagePlaceholder')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </div>
            <div class="mt-2 d-flex justify-content-center">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="envoyer()"
                :disabled="isSending"
                class="d-flex align-items-center"
              >
                <b-spinner v-if="isSending" small class="mr-1" />
                {{ $t('button.send') }}
              </b-button>
            </div>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BSpinner,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BButton,
  BCardHeader,
  BFormTextarea,
} from 'bootstrap-vue';

// import utilsService from '@/services/utils/utils.service';

import TipsModalCreateFromLastRecord from '@/components/TipsModalCreateFromLastRecord.vue';
import localstorageService from '@/services/localstorage/localstorage.service';
import { mapMutations, mapActions } from 'vuex';
import vSelect from 'vue-select';
import locale from '@core/layouts/components/app-navbar/components/Locale.vue';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { quillEditor } from 'vue-quill-editor';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
// const kb = utilsService.getListFormulaires()
// --------------------------------------------------
import paramsStoreModule from '@/store/params';
import RequiredStar from '@/components/RequiredStar.vue';

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils';

export default {
  components: {
    BSpinner,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BCardHeader,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,

    TipsModalCreateFromLastRecord,
    vSelect,
    quillEditor,
    locale,
    RequiredStar,
  },
  directives: {
    Ripple,
  },
  setup() {
    // const editorOption = {
    //   modules: {
    //     toolbar: '#quill-toolbar',
    //   },
    //   placeholder: 'Description de votre message',
    // };
    // const formulairesByGroup = utilsService.getListFormulairesByGroup();
    // const appLocales = [
    //   { code: 'en', name: 'anglais' },
    //   { code: 'fr', name: 'francais' },
    // ];

    const requiredStoreModules = [
      { path: 'params', module: paramsStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  data() {
    return {
      isSending: false,
      objectMsg: {
        nomPrenoms: '',
        mail: '',
        sujet: '',
        message: '',
        contact: '',
        type: 'investisseur',
        token: '',
      },
      optionsSujet: ["Problèmes d'utilisation", 'Suggestions', 'Autres'],
    };
  },

  computed: {
    // ...mapState("auth", {
    //   isAuthenticated: "isAuthenticated",
    // }),
    // filteredKB() {
    //   const knowledgeBaseSearchQueryLower =
    //     this.knowledgeBaseSearchQuery.toLowerCase();
    //   // if(this.selectedSectorId){ // filtre choix de secteur
    //   return this.kb.filter((item) => {
    //     const condition =
    //       (this.selectedSectorId
    //         ? item.sector == this.selectedSectorId
    //         : true) &&
    //       item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower);
    //     return condition;
    //   });
    // },
    // currentLocaleName() {
    //   if (this.$i18n.locale === "fr") return "francais";
    //   return "anglais";
    // },
  },
  watch: {
    // selectedSector: {
    //   handler(val, old) {
    //     console.log("home ---> selectedSector: ", val, old);
    //     this.selectedSectorId = this.selectedSector._id
    //       ? this.selectedSector._id
    //       : null;
    //     this.filteredKB;
    //   },
    // },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  mounted() {
    // console.log(this.formulairesByGroup);
    if (localstorageService.getUserId()) {
      this.kbOptions = { publicOnly: false, privateOnly: true };
    } else {
      this.kbOptions = { publicOnly: true, privateOnly: false };
    }
    // this.loadSectors();
  },
  methods: {
    ...mapActions('params', {
      action_sendMailNousContacter: 'sendMailNousContacter',
    }),
    // ---------------------------------------------------------
    envoyer() {
      this.$refs.formRules.validate().then(async (success) => {
        if (success) {
          this.isSending = true;
          console.log('this.objectMsg: 🩸🩸🩸 ', this.objectMsg);
          // debugger;
          // ReCaptcha v3
          // (optional) Wait until recaptcha has been loaded.
          await this.$recaptchaLoaded();
          // Execute reCAPTCHA with action "login".
          const token = await this.$recaptcha('login');
          if (!token) {
            this.isLoading = false;
            return;
          }
          const payload = {
            fullname: this.objectMsg.nomPrenoms,
            email: this.objectMsg.mail,
            subject: this.objectMsg.sujet,
            content: this.objectMsg.message,
            contact: this.objectMsg.contact,
            type: 'investisseur',
            token,
          };

          console.log('PAYLOAD: 🔰🔰🔰 ', payload);
          this.action_sendMailNousContacter(payload)
            .then((res) => {
              console.log('res: ', res);
              if (res.status === 201) {
                // this.objectMsg = {
                //   nomPrenoms: '',
                //   mail: '',
                //   sujet: '',
                //   message: '',
                //   contact: '',
                //   type: 'investisseur',
                //   token: '',
                // };

                this.isSending = false;
                // success alert
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opération réussie',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                });

                // redition to home
                this.$router.replace({ name: 'home-public' });
              }
            })
            .catch((err) => {
              console.log('err: ', err);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: err.message || 'Une erreur est survenue',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              });
              this.isSending = false;
            });
        }
      });
    },
    // loadSectors() {
    //   const currentParams = {
    //     page: this.paginationData.currentPage - 1,
    //     perPage: this.paginationData.perPage,
    //   };
    //   this.fetchSecteurs({ params: currentParams || null })
    //     .then((res) => {
    //       this.sectors = res.data.secteurs;
    //       this.sectorsTotal = res.data.total;
    //       this.sectors.forEach((currentValue, index, arr) => {
    //         this.sectorsCustom.push({
    //           nom: currentValue.nom,
    //           _id: currentValue._id,
    //         });
    //       });
    //       // console.log('res this.sectorsCustom: ', this.sectorsCustom)
    //     })
    //     .catch((err) => {
    //       this.isLoadingTableData = false;
    //       console.log(err);
    //     });
    // },
  },
};
</script>

<style scoped>
.contact {
  position: relative;
  width: 100%;
  /* min-height: 100vh; */
}

.contact-wrapper {
  margin: 0 auto;
  max-width: 1300px;
  height: 100%;
  /* min-height: 100vh; */

  padding: 3em 1em;
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  gap: 3em;
}
.wrapper-investor-page {
  margin: 0 auto;
  max-width: 1300px;
  padding: 1em;
}

.contact .back-image {
  position: absolute;
  inset: 0;
  z-index: -20;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contact .filigrane-color {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: -10;

  background-color: #06250fb2;
}

.left-infos {
  color: #fff;
  text-shadow: 2px 2px 1px #00000090;
}

.left-infos h1 {
  color: #fff;
  font-weight: bold;
  font-size: 35px;
}

.left-infos h3 {
  color: #fff;
}

.left-infos p {
  margin: 1em 0;
  line-height: 30px;
  color: #fff;
  font-size: 16px;
}

.contact-form {
  border-radius: 1em;
  padding: 1em 2em 2em 2em;
  background-color: #fff;
  min-width: 330px;
}

.contact-form .logo {
  max-width: 190px;
  display: block;
}

.contact-form .logo img {
  width: 190px;
}

.contact-form h1 {
  margin: 0em auto 1em auto;
  /* margin: 0.5em auto 0.4em auto; */
  font-size: 37px;
  text-align: center;
}

.contact-form p {
  display: inline-block;
  margin: 1em 0 2em 0;
}

.contact-names-email {
  display: grid;
  gap: 0 2em;
}

/* MEDIA QUERIES */
@media (min-width: 576px) {
  .contact-wrapper {
    flex-direction: row;
    padding: 4em 3em;
  }

  .contact-form {
    padding: 3em;
  }

  .contact-form {
    width: 100%;
    max-width: 650px;
  }

  .left-infos {
    width: 55%;
  }

  .contact-names-email {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .left-infos h1 {
    font-size: 55px;
  }

  .left-infos p {
    font-size: 25px;
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}
</style>
